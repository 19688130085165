
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ThemeProviderWrapper from './theme/ThemeProvider';
import AppRouter from './router';
import CustomSnackbarProvider from './components/SnackbarProvider'
import './globalStyles.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProviderWrapper>
          <CustomSnackbarProvider>
            <CssBaseline />
            <AppRouter />
          </CustomSnackbarProvider >
      </ThemeProviderWrapper>
    </LocalizationProvider >
  );
}

export default App;
