import { Box, styled, Typography } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { sideBarRoutes } from './items'
import { useCheckPermission } from '../../../../../hooks/useCheckPermission'
import { useSidebarClose, useUser } from '../../../../../contexts/GlobalContext'
import { adminRoles } from '../../../../../constants'

const SidebarMenu = () => {
  const { checkPermission } = useCheckPermission()
  const { role } = useUser()

  const menuItemsGenerator = () => {
    let tempMenuItems = []

    if(checkPermission('GET_ADMIN_LIST')) tempMenuItems.push(sideBarRoutes.adminsRoute)
    if(checkPermission('GET_CUSTOMER_LIST')) tempMenuItems.push(sideBarRoutes.customerRoute)
    if(checkPermission('GET_COUNTRY_LIST')) tempMenuItems.push(sideBarRoutes.countriesRoute)
    if(role === adminRoles.VENDOR_ADMIN) {
      if(checkPermission('GET_MY_VENDOR_VENDOR_DETAIL')) tempMenuItems.push(sideBarRoutes.myVendorDetailsRoute)
    }else{
      if(checkPermission('GET_VENDOR_LIST')) tempMenuItems.push(sideBarRoutes.vendorsRoute)
    } 
    if(role === adminRoles.SUPER_ADMIN) tempMenuItems.push(sideBarRoutes.allOrderRoute)
    if(checkPermission('GET_VEHICLE_LIST')) tempMenuItems.push(sideBarRoutes.carsRoute)
    if(checkPermission('GET_BRANDLIST')) tempMenuItems.push(sideBarRoutes.brandsRoute)
    if(checkPermission('GET_CLASS_LIST')) tempMenuItems.push(sideBarRoutes.classesRoute)
    if(checkPermission('GET_STATIC_PAGE_LIST')) tempMenuItems.push(sideBarRoutes.editStaticPagesRoute)
    if(checkPermission('ROLE_CONTROLLER')) tempMenuItems.push(sideBarRoutes.administrationRoute)
    
    return tempMenuItems
  }

  return (
    <> 
      {menuItemsGenerator().map((route, index) => <CustomSideBar key={index} route={route} Icon={route.icon}/>)}
    </>
  );
}

export default SidebarMenu

const CustomSideBar = ({route, Icon}) => {
  const navigate = useNavigate();
  const handleCloseSideBar = useSidebarClose()
  const location = useLocation()
  
  const handleClick = link => {
    navigate(link)
    handleCloseSideBar()
  }

  const activeTabFinder = () => {
    const slicedMyLocation = location.pathname
      .split('/')
      .slice(0, 4)
      .join('/')

    const slicedRouteLink = route.link
      .split('/')
      .slice(0, 4)
      .join('/')

    return slicedMyLocation === slicedRouteLink
  }

  return (
        <SideBarItem active={activeTabFinder() ? 'true' : 'false'} onClick={() => handleClick(route.link)}>
          <Icon className='icon'/>
          <Box className='name-wrapper'>
            <Typography className='name' style={{textDecoration: 'none'}}>{route.name}</Typography>
          </Box>
        </SideBarItem>
  )
}

const SideBarItem = styled(Box)(
  ({ active }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px;
    padding-left: 30px;
    cursor: pointer;
    color: ${active === 'true' ? '#f33' : '#242E6F'};
    border-right: ${active === 'true' && '5px solid #f33'} ;
    transition: 0.1s all, border-right 0s;
    :hover{
      background: #f2f5f9;
      color: #f33;
      border-right: 5px solid #f33;
    }
    .icon{
        font-size: 19px;
    }
    .name-wrapper{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 8px;
      .name{
        font-size: 13px;
        font-weight: 700;
        margin-left: 8px;
      };
      .bullet{
        width: 6px;
        height: 6px;
        background: #f33;
        border-radius: 50%;
      }
    }
    
`
);