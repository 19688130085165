export const ROUTE_CONSTANTS = {
    ROOT: {
        RELATIVE: "/",
        ABSOLUTE: "/",
    },
    ROOT_STAR: {
        RELATIVE: "/*",
        ABSOLUTE: "/*",
    },
    PUBLIC_ROUTES: {
        NOT_PERMITTED: {
            RELATIVE: "not-permitted",
            ABSOLUTE: "/not-permitted",
        },
        STATIC_PAGES: {
            ROOT: {
                RELATIVE: ":name/:language",
                ABSOLUTE: "/:name/:language",
            },
            GET_BY_DATA: (name, language) => {
                return {
                    RELATIVE: `/${name}/${language}`,
                    ABSOLUTE: `/${name}/${language}`,
                }
            }
        },
    },
    AUTH: {
        ROOT: {
            RELATIVE: "auth",
            ABSOLUTE: "/auth",
        },
        LOGIN: {
            RELATIVE: "login",
            ABSOLUTE: "/auth/login",
        },
        RESET_PASSWORD: {
            RELATIVE: "reset-password",
            ABSOLUTE: "/auth/reset-password",
        },
        FORGOT_PASSWORD: {
            RELATIVE: "forgot-password",
            ABSOLUTE: "/auth/forgot-password",
        },
    },
    DASHBOARD: {
        ROOT: {
            RELATIVE: "dashboard",
            ABSOLUTE: "/dashboard",
        },
        ANALYTICS: {
            RELATIVE: "analytics",
            ABSOLUTE: "/dashboard/analytics",
        },
        USERS: {
            ROOT: {
                RELATIVE: "users",
                ABSOLUTE: "/dashboard/users",
            },
            PROFILE: {
                ROOT: {
                    RELATIVE: "me",
                    ABSOLUTE: "/dashboard/me/",
                },
            },
            CUSTOMERS: {
                ROOT: {
                    RELATIVE: "customers",
                    ABSOLUTE: "/dashboard/users/customers",
                },
                DETAILS: {
                    RELATIVE: "customers/:id",
                    ABSOLUTE: "/dashboard/users/customers/:id",
                },
                GET_BY_DATA: (id) => {
                    return {
                        RELATIVE: `/customers/${id}`,
                        ABSOLUTE: `/dashboard/users/customers/${id}`,
                    }
                }
            },
            ADMINS: {
                ROOT: {
                    RELATIVE: "admins",
                    ABSOLUTE: "/dashboard/users/admins",
                },
                DETAILS: {
                    RELATIVE: "admins/:id",
                    ABSOLUTE: "/dashboard/users/admins/:id",
                },
                GET_BY_DATA: (id) => {
                    return {
                        RELATIVE: `/admins/${id}`,
                        ABSOLUTE: `/dashboard/users/admins/${id}`,
                    }
                }
            },
        },
        LOGISTICS: {
            ROOT: {
                RELATIVE: "logistics",
                ABSOLUTE: "/dashboard/logistics",
            },
            ALL_ORDERS: {
                ROOT: {
                    RELATIVE: "all-orders",
                    ABSOLUTE: "/dashboard/logistics/all-orders",
                },
            },
            COUNTRIES: {
                ROOT: {
                    RELATIVE: "countries",
                    ABSOLUTE: "/dashboard/logistics/countries",
                },
                ADD_NEW_COUNTRY: {
                    ROOT: {
                        RELATIVE: "countries/add-new",
                        ABSOLUTE: "/dashboard/logistics/countries/add-new",
                    }
                },
                UPDATE_COUNTRY: {
                    ROOT: { 
                        RELATIVE: "countries/update",
                        ABSOLUTE: "/dashboard/logistics/countries/update",
                    }
                },
                DETAILS: {
                    RELATIVE: "countries/:id",
                    ABSOLUTE: "/dashboard/countries/:id",
                },
                GET_BY_DATA: (id) => {
                    return {
                        RELATIVE: `countries/${id}`,
                        ABSOLUTE: `/dashboard/countries/${id}`,
                    }
                }
            },
            VENDORS: {
                ROOT: {
                    RELATIVE: "vendors",
                    ABSOLUTE: "/dashboard/logistics/vendors",
                },
                ADD_NEW_VENDOR: {
                    ROOT: {
                        RELATIVE: "vendors/add-new",
                        ABSOLUTE: "/dashboard/logistics/vendors/add-new",
                    },
                },
                UPDATE_VENDOR: {
                    ROOT: {
                        RELATIVE: "vendors/update",
                        ABSOLUTE: "/dashboard/logistics/vendors/update",
                    },
                },
                DETAILS: {
                    ROOT: {
                        RELATIVE: "vendors/:id",
                        ABSOLUTE: "/dashboard/logistics/vendors/:id",
                    },
                    MY_VENDOR: {
                        RELATIVE: "vendors/my-vendor",
                        ABSOLUTE: "/dashboard/logistics/vendors/my-vendor",
                    },
                    ADD_NEW_INVENTORY: {
                        ROOT: {
                            RELATIVE: "vendors/:id/inventory/add-new",
                            ABSOLUTE: "/dashboard/logistics/vendors/:id/inventory/add-new",
                        },
                    },
                    UPDATE_INVENTORY: {
                        ROOT: {
                            RELATIVE: "vendors/:id/inventory/update",
                            ABSOLUTE: "/dashboard/logistics/vendors/:id/inventory/update",
                        },
                    },
                    INVENTORY_DETAILS: {
                        ROOT: {
                            RELATIVE: "vendors/:id/inventory/:id",
                            ABSOLUTE: "/dashboard/logistics/vendors/:id/inventory/:id",
                        },
                    },
                    ORDER_DETAILS: {
                        ROOT: {
                            RELATIVE: "vendors/:id/orders/:id",
                            ABSOLUTE: "/dashboard/logistics/vendors/:id/orders/:id",
                        },
                    },
                },
                GET_BY_DATA: (vendorId) => {
                    return {
                        RELATIVE: `vendors/${vendorId}`,
                        ABSOLUTE: `/dashboard/logistics/vendors/${vendorId}`,
                        INVENTORY_DETAILS: {
                            ROOT: {
                                RELATIVE: `vendors/${vendorId}/inventory/:id`,
                                ABSOLUTE: `/dashboard/logistics/vendors/${vendorId}/inventory:id`,
                            },
                        },
                        ADD_NEW_INVENTORY: {
                            ROOT: {
                                RELATIVE: `vendors/${vendorId}/inventory/add-new`,
                                ABSOLUTE: `/dashboard/logistics/vendors/${vendorId}/inventory/add-new`,
                            },
                        },
                        UPDATE_INVENTORY: {
                            ROOT: {
                                RELATIVE: `vendors/${vendorId}/inventory/update`,
                                ABSOLUTE: `/dashboard/logistics/vendors/${vendorId}/inventory/update`,
                            },
                        },
                        GET_BY_DATA: (inventoryId) => {
                            return {
                                RELATIVE: `vendors/${vendorId}/inventory/${inventoryId}`,
                                ABSOLUTE: `/dashboard/logistics/vendors/${vendorId}/inventory/${inventoryId}`,
                            }
                        },
                        GET_ORDER_BY_DATA: (orderId) => {
                            return {
                                RELATIVE: `vendors/${vendorId}/orders/${orderId}`,
                                ABSOLUTE: `/dashboard/logistics/vendors/${vendorId}/orders/${orderId}`,
                            }
                        }
                    }
                },
            },
        },
        VEHICLES : {
            ROOT: {
                RELATIVE: "vehicle",
                ABSOLUTE: "/dashboard/vehicle",
            },
            CARS: {
                ROOT: {
                    RELATIVE: "cars",
                    ABSOLUTE: "/dashboard/vehicle/cars",
                },
                ADD_NEW_CAR: {
                    ROOT: {
                        RELATIVE: "cars/add-new",
                        ABSOLUTE: "/dashboard/vehicle/cars/add-new",
                    },
                },
                UPDATE_CAR: {
                    ROOT: {
                        RELATIVE: "cars/update",
                        ABSOLUTE: "/dashboard/vehicle/cars/update",
                    },
                },
                DETAILS: {
                    RELATIVE: "cars/:id",
                    ABSOLUTE: "/dashboard/vehicle/cars/:id",
                },
                GET_BY_DATA: (id) => {
                    return {
                        RELATIVE: `cars/${id}`,
                        ABSOLUTE: `/dashboard/vehicle/cars/${id}`,
                    }
                }
            },
            BRANDS: {
                ROOT: {
                    RELATIVE: "brands",
                    ABSOLUTE: "/dashboard/vehicle/brands",
                },
                ADD_NEW_BRAND: {
                    ROOT: {
                        RELATIVE: "brands/add-new",
                        ABSOLUTE: "/dashboard/vehicle/brands/add-new",
                    },
                },
                UPDATE_BRAND: {
                    ROOT: {
                        RELATIVE: "brands/update",
                        ABSOLUTE: "/dashboard/vehicle/brands/update",
                    },
                },
            },
            CAR_CLASSES: {
                ROOT: {
                    RELATIVE: "class",
                    ABSOLUTE: "/dashboard/vehicle/class",
                },
                ADD_NEW_CLASS: {
                    ROOT: {
                        RELATIVE: "class/add-new",
                        ABSOLUTE: "/dashboard/vehicle/class/add-new",
                    },
                },
                UPDATE_CLASS: {
                    ROOT: {
                        RELATIVE: "class/update",
                        ABSOLUTE: "/dashboard/vehicle/class/update",
                    },
                },
            },
        },
        ADMINISTRATION: {
            ROOT: {
                RELATIVE: "administration",
                ABSOLUTE: "/dashboard/administration",
            },
        },
        EDIT_STATIC_PAGES: {
            ROOT: {
                RELATIVE: "edit-static-pages",
                ABSOLUTE: "/dashboard/edit-static-pages",
            }
        },
    }
};
