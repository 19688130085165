import { AxiosInstance } from "../index";

export const adminService = {
    //**** users and admins *****
    getAdminList: data => AxiosInstance.post("admin/auth/admins/list", fixPageNumber(data)),
    getUserDetail: id => AxiosInstance.get(`admin/auth/userdetail/${id}`), //***** get user details should be get admin details and get customer details
    adminInvitation: body => AxiosInstance.post("admin/auth/invitation", body),
    getCustomersList: data => AxiosInstance.post("admin/auth/customers/list", fixPageNumber(data)),
    updateAdminDetails: (id, data) => AxiosInstance.put(`admin/auth/update-admin/${id}`, data),
    updateProfileDetails: data => AxiosInstance.put(`admin/auth/update-profile/`, data),
    updateAdminPassword: (id, data) => AxiosInstance.put(`admin/auth/set-admin-password/${id}`, data),
    updateMyPassword: data => AxiosInstance.post(`admin/auth/set-password`, data),
    toggleUsers: (id, data) => AxiosInstance.put(`admin/auth/toggle/${id}`, data),

    //**** vehicles *****
    createVehicle: data => AxiosInstance.post("admin/vehicle/", data),
    updateVehicle: (id, data) => AxiosInstance.put(`admin/vehicle/${id}`, data),
    getVehicleClass: () => AxiosInstance.get("classes"),
    getVehicleList: data => AxiosInstance.post("admin/vehicle/list", fixPageNumber(data)),
    getVehicleDetails: id => AxiosInstance.get(`admin/vehicle/${id}`),
    toggleVehicleStatus: (id, data) => AxiosInstance.put(`admin/vehicle/toggle/${id}`, data),
    getBrandsList: data => AxiosInstance.post('admin/brands/list', fixPageNumber(data)),
    brandToggle: (id, data) => AxiosInstance.put(`admin/brands/toggle/${id}`, data),
    createBrand: data => AxiosInstance.post(`admin/brands/`, data),
    updateBrand: (id, data) => AxiosInstance.put(`admin/brands/${id}`, data),
    getClassesList: data => AxiosInstance.post('admin/classes/list', fixPageNumber(data)),
    classToggle: (id, data) => AxiosInstance.put(`admin/classes/toggle/${id}`, data),
    createClass: data => AxiosInstance.post(`admin/classes`, data),
    updateClass: (id, data) => AxiosInstance.put(`admin/classes/${id}`, data),
    getAttributes: vehicleId => AxiosInstance.get(`admin/vehicle/${vehicleId}/attribute/list`),
    updateAttribute: (vehicleId, attributeId, data) => AxiosInstance.put(`admin/vehicle/${vehicleId}/attribute/${attributeId}`, data), 
    createAttribute: (vehicleId, data) => AxiosInstance.post(`admin/vehicle/${vehicleId}/attribute/`, data), 

    //**** vendors *****
    getVendorsList: data => AxiosInstance.post("admin/vendors/list", fixPageNumber(data)),
    createVendor: data => AxiosInstance.post("admin/vendors", data),
    updateVendor: (id, data) => AxiosInstance.put(`admin/vendors/${id}`, data),
    getVendorDetails: id => AxiosInstance.get(`admin/vendors/${id}`),
    getMyVendorDetails: () => AxiosInstance.get(`admin/vendors/my-vendor`),
    toggleVendorStatus: (id, data) => AxiosInstance.put(`admin/vendors/toggle/${id}`, data), 
    vendorWeekends: (id, data) => AxiosInstance.put(`admin/vendors/weekends/${id}`, data), 
    vendorHolidays: (id, data) => AxiosInstance.put(`admin/vendors/holidays/${id}`, data), 
    addBinding: (id, data) => AxiosInstance.put(`admin/vendors/bindings/${id}`, data),
    getVendorTerms: vendorId => AxiosInstance.get(`admin/vendors/${vendorId}/vendor-terms/list`),
    createTerm: (vendorId, data) => AxiosInstance.post(`admin/vendors/${vendorId}/vendor-terms`, data),
    updateTerm: (vendorId, termId, data) => AxiosInstance.put(`admin/vendors/${vendorId}/vendor-terms/${termId}`, data),
    updateWorkingHours: (vendorId, dayId, data) => AxiosInstance.put(`admin/vendors/${vendorId}/work-hours/${dayId}`, data),
    
    //**** inventory *****
    createInventory: data => AxiosInstance.post("admin/vehicle/inventory", data),
    updateInventory: (id, data) => AxiosInstance.put(`admin/vehicle/inventory/${id}`, data),
    getInventoryList: data => AxiosInstance.post("admin/vehicle/inventory/list", fixPageNumber(data)),
    getInventoryDetails: id => AxiosInstance.get(`admin/vehicle/inventory/${id}`),
    toggleInventoryStatus: (id, data) => AxiosInstance.put(`admin/vehicle/inventory/toggle/${id}`, data), 
    blockInventory: (id, data) => AxiosInstance.post(`admin/vendors/inventory-block/${id}`, data), 
    removeBlockedInventory: (id, data) => AxiosInstance.delete(`admin/vendors/inventory-block/${id}`, data), 

    //**** countries *****
    getCountriesList: data => AxiosInstance.post("admin/country/list", fixPageNumber(data)),
    createCountry: data => AxiosInstance.post("admin/country", data),
    updateCountry: (id, data) => AxiosInstance.put(`admin/country/${id}`, data),
    countryToggle: (id, data) => AxiosInstance.put(`admin/country/toggle/${id}`, data), 

    //**** orders *****
    getOrdersList: (vendorId, data) => AxiosInstance.post(`admin/orders/list/${vendorId}`, fixPageNumber(data)),
    getOrderDetails: id => AxiosInstance.get(`admin/orders/${id}`),
    orderUpdateStatus: (id, data) => AxiosInstance.put(`admin/orders/status/${id}`, data),
    refundOrder: (id,data) => AxiosInstance.post(`admin/orders/refund/${id}`, data),

    //**** Static pages *****
    editStaticPage: (id, data) => AxiosInstance.put(`admin/misc/static/${id}`, data),
    getStaticPage: (type, language) => AxiosInstance.get(`misc/static/${type}/${language}`),
    getStaticPages: () => AxiosInstance.get(`admin/misc/static`),

    //**** Notification *****
    deleteOrderNotification: id => AxiosInstance.delete(`admin/misc/notification/${id}`),
    getMyNotifications: () => AxiosInstance.get("admin/misc/my-notifications"),
    readNotification: id => AxiosInstance.put(`admin/misc/notification/read/${id}`),

    //**** general *****
    getBrands: data => AxiosInstance.post("admin/brands/list", data),
    getPermission: () => AxiosInstance.get("admin/misc/role-perms"),
    togglePermission: (id, data) => AxiosInstance.put(`admin/misc/toggle-perm/${id}`, data),
    getCurrencyExchange: () => AxiosInstance.get("admin/misc/currency-exchange"),
    getReports: () => AxiosInstance.get("admin/misc/my-reports")
}

const fixPageNumber = data => {
    //***** Pagination component in material UI start from page 0, our backend start from 1, 
    // For dealing with API call and handle pagination functions I did this manipulation *****
    let manipulatedPage = (data.page + 1)
    data['page'] = manipulatedPage

    return data
}
