
import { ROUTE_CONSTANTS } from '../../../../../constants/AppRoutes'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PublicIcon from '@mui/icons-material/Public';
import StoreIcon from '@mui/icons-material/Store';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import ClassIcon from '@mui/icons-material/Class';
import SecurityIcon from '@mui/icons-material/Security';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';

const adminsRoute = {
  name: 'Admins',
  link: ROUTE_CONSTANTS.DASHBOARD.USERS.ADMINS.ROOT.ABSOLUTE,
  badgeTooltip: 'Admin List',
  icon: SupportAgentIcon

}
const customerRoute = {
  name: 'Customers',
  link: ROUTE_CONSTANTS.DASHBOARD.USERS.CUSTOMERS.ROOT.ABSOLUTE,
  badgeTooltip: 'Customer List',
  icon: PeopleAltIcon
}
const countriesRoute = {
  name: 'Countries',
  link: ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.COUNTRIES.ROOT.ABSOLUTE,
  badgeTooltip: 'Country List',
  icon: PublicIcon
}
const vendorsRoute = {
  name: 'Vendors',
  link: ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.ROOT.ABSOLUTE,
  badgeTooltip: 'Vendor List',
  icon: StoreIcon
}

const allOrderRoute = {
  name: 'All Orders',
  link: ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.ALL_ORDERS.ROOT.ABSOLUTE,
  badgeTooltip: 'All Orders',
  icon: StoreIcon
}

const myVendorDetailsRoute = {
  name: 'My vendor',
  link: ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.DETAILS.MY_VENDOR.ABSOLUTE,
  badgeTooltip: 'My vendor',
  icon: StoreIcon
}

const carsRoute = {
  name: 'Cars',
  link: ROUTE_CONSTANTS.DASHBOARD.VEHICLES.CARS.ROOT.ABSOLUTE,
  badge: '',
  badgeTooltip: 'Added in version 3.0',
  icon: DriveEtaIcon
}
const brandsRoute = {
  name: 'Brands',
  link: ROUTE_CONSTANTS.DASHBOARD.VEHICLES.BRANDS.ROOT.ABSOLUTE,
  badge: '',
  badgeTooltip: 'Updated',
  icon: BrandingWatermarkIcon
}
const classesRoute = {
  name: 'Car Classes',
  link: ROUTE_CONSTANTS.DASHBOARD.VEHICLES.CAR_CLASSES.ROOT.ABSOLUTE,
  badge: '',
  badgeTooltip: 'Updated',
  icon: ClassIcon
}

const administrationRoute = {
  name: 'Permissions',
  link: ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATION.ROOT.ABSOLUTE,
  icon: SecurityIcon
}

const editStaticPagesRoute = {
  name: 'Edit Static Pages',
  link: ROUTE_CONSTANTS.DASHBOARD.EDIT_STATIC_PAGES.ROOT.ABSOLUTE,
  icon: FormatAlignCenterIcon
}

export const sideBarRoutes = {
  adminsRoute,
  customerRoute,
  countriesRoute,
  vendorsRoute,
  allOrderRoute,
  carsRoute,
  brandsRoute,
  classesRoute,
  administrationRoute,
  myVendorDetailsRoute,
  editStaticPagesRoute
}